import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    translations: { [key: string]: string } = {};
    private currentLanguage = new BehaviorSubject<string>('');
    currentLanguage$ = this.currentLanguage.asObservable();

    constructor(private http: HttpClient) {
        const lang = localStorage.getItem('lang') ?? 'en';
        this.loadTranslations(lang);
    }
    
    private loadTranslations(language: string) {
        const filePath = `/assets/translations/${language}.json`;
        this.http.get<{ [key: string]: string }>(filePath).subscribe(
            (data) => {
                this.translations = data;
                localStorage.setItem('lang', language);
                this.currentLanguage.next(language);
            },
            (error) => {
                console.error(`Error loading translations for language ${language}:`, error);
            }
        );
    }

    /**
     * Fires on language changed.
     * @returns {Observable<any>}
     */
    onLanguageChange(): Observable<any> {
        return this.currentLanguage.asObservable();
    }

    translate(key: string): string {
        return this.translations[key] || key;
    }

    changeLanguage(language: string) {
        this.loadTranslations(language);
    }
}
