import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiConstants } from '../constants/api.constant';

@Pipe({
  name: 'File'
})
export class FilePipe implements PipeTransform {
  transform(value: string | null | undefined,fileType = 'image'): string {
    if (!value) {
      return '';
    }
    const apiUrl =
    fileType === 'image'
      ? environment.fileBaseUrl + ApiConstants.routes.getImage
      : environment.fileBaseUrl + ApiConstants.routes.downloadFile;
    return apiUrl + value.split('\\').join('||');
    
  }

}
