import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbPaginationModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequiredLabelDirective } from './directives/required-label/required-label.directive';
import { NumericDirective } from './directives/numeric.directive';
import { GoogleMapsModule } from '@angular/google-maps';
import { FilePipe } from '../core/pipes/file.pipe';
import { AudioRecorderComponent } from './audio-recorder/audio-recorder.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { ImageSliderComponent } from './image-slider/image-slider.component';
import { TranslationService } from './services/translation.service';
import { TranslationPipe } from './pipes/translation.pipe';
// import { CdkStepperModule } from '@angular/cdk/stepper';
// import { NgStepperModule } from 'angular-ng-stepper';


@NgModule({
  declarations: [
    RequiredLabelDirective,
    NumericDirective,
    FilePipe,
    TranslationPipe,
    AudioRecorderComponent,
    FileUploaderComponent,
    ImageSliderComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgbRatingModule,
    NgbPaginationModule,
    RequiredLabelDirective,
    NumericDirective,
    GoogleMapsModule,
    FilePipe,
    TranslationPipe,
    AudioRecorderComponent,
    FileUploaderComponent,
    ImageSliderComponent,
    // CdkStepperModule,
    // NgStepperModule
  ],
  providers: [
  ]
})
export class SharedModule { }
