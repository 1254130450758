<!-- Main Header-->
<header class="main-header header-style-two alternate direction-ltr ltr-text">

  <!-- bootstrap navbar starts here -->
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <nav class="navbar navbar-expand-lg navbar-light logo ">
          <!-- <a class="navbar-brand" href="#">Navbar</a> -->
          <a class="navbar-brand" [routerLink]="formRoutes.home">
            <img src="assets/images/header-logo.png" alt="" title="">
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon">
              <i class="fa fa-bars fa-1" aria-hidden="true"></i>
            </span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item active" *ngIf="isLogin">
                <select class="form-control filter-field" [ngModel]="currentLanguage" (change)="onLanguageChange($event)">
                  <option disabled value="">{{ 'changeLanguage' | translate }}</option>
                  <option *ngFor="let language of languageOptions" [value]="language.value">{{ language.key | translate }}</option>
                </select>
              </li>
              <li class="nav-item dropdown" *ngIf="isLogin">
                <a *ngIf="userType == '2'" [routerLink]="formRoutes.postJob" class="btn-style-link">{{ 'postJob' | translate }} </a>
              </li>
              <li class="nav-item dropdown" *ngIf="isLogin">
                <a class="nav-link dropdown-toggle profilePic" href="#" id="navbarDropdown" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img
                    [src]="userDetails?.image_url ? (userDetails?.image_url | File:'image'): './assets/images/resource/candidate-3.png'">
                    <span class="user-name">{{ userDetails?.first_name + " " + userDetails?. last_name}}</span>
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" href="#"
                    [routerLink]="userType == '3' ? formRoutes.editWorkerProfile : formRoutes.editCustomerProfile">{{ 'profile'| translate }}</a>
                  <a class="dropdown-item" href="#" *ngIf="userType == '3'" [routerLink]="formRoutes.workerJobs">{{ 'viewJobs' | translate }}</a>
                  <a class="dropdown-item" href="#" *ngIf="userType == '2'"
                    [routerLink]="formRoutes.jobManagement">{{ 'manageJobs' | translate }}</a>
                  <a class="dropdown-item" href="#" [routerLink]="formRoutes.favoriteList">{{ 'viewFavorites' | translate }}</a>
                  <a class="dropdown-item" (click)="logout()">{{ 'signOut' | translate }}</a>
                </div>
              </li>
              <li class="nav-item signup-in-wrapper" *ngIf="!isLogin">
                <select class="form-control filter-field" [value]="currentLanguage" (change)="onLanguageChange($event)">
                  <option disabled value="">{{ 'changeLanguage' | translate }}</option>
                  <option *ngFor="let language of languageOptions" [value]="language.value">{{ language.key | translate }}</option>
                </select>
                <a [routerLink]="formRoutes.signin" class="call-modal theme-btn btn-style-one-outline ">{{ 'signIn' | translate }}</a>
                <a [routerLink]="formRoutes.signup" class="theme-btn btn-style-one-outline ">{{ 'signUp' | translate }}</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>


  <!-- bootstrap navbar ends here -->





  <div class="">
    <!-- Main box -->
    <div class="main-box logged-in" style="display: none;">
      <!--Nav Outer -->
      <div class="nav-outer">
        <div class="logo-box">
          <div class="logo">
            <a [routerLink]="formRoutes.home">
              <img src="assets/images/header-logo.png" alt="" title="">

            </a>
          </div>
        </div>
      </div>

      <!-- <div class="header-search">-->
      <!-- <div class="input-group"> -->
      <!-- <div class="input-group-prepend"> -->
      <!-- <button class="btn" type="button" aria-haspopup="true" aria-expanded="false"> -->
      <!-- <i class="fa fa-search"></i> -->
      <!-- </button> -->
      <!-- <div class="dropdown-menu"> -->
      <!-- <a class="dropdown-item" href="#">Option 1</a> -->
      <!-- <a class="dropdown-item" href="#">Option 2</a> -->
      <!-- <a class="dropdown-item" href="#">Option 3</a> -->
      <!-- </div> -->
      <!-- </div> -->
      <!-- <input type="text" class="form-control search-field" placeholder="Search" /> -->
      <!-- </div> -->
      <!-- </div> -->

      <div class="outer-box">
        <select class="form-control filter-field">
          <option disabled value="">{{ 'changeLanguage' | translate }}</option>
          <option *ngFor="let language of languageOptions" [value]="language.value">{{ language.key | translate }}</option>
        </select>
        <!-- Post Login -->
        <div class="btn-box post-login" *ngIf="isLogin">
          <!-- <a [routerLink]="formRoutes.messages" class="btn-style-link call-modal">Messages</a> -->
          <a *ngIf="userType == '2'" [routerLink]="formRoutes.postJob" class="btn-style-link">{{ 'postJob' | translate }}</a>

          <div class="dropdown user-dropdown">
            <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <!-- <img src="./assets/images/resource/candidate-3.png" />  -->

              <img
                [src]="userDetails?.image_url ? (userDetails?.image_url | File:'image') : './assets/images/resource/candidate-3.png'">
              <!-- <span class="user-name">Hello {{ userDetails?. first_name}}</span> -->
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item"
                [routerLink]="userType == '3' ? formRoutes.editWorkerProfile : formRoutes.editCustomerProfile">{{ 'profile'| translate }}</a>
              <a class="dropdown-item" *ngIf="userType == '3'" [routerLink]="formRoutes.workerJobs">{{ 'viewJobs' | translate }}</a>
              <a class="dropdown-item" *ngIf="userType == '2'" [routerLink]="formRoutes.jobManagement">{{ 'manageJobs' | translate }}</a>
              <a class="dropdown-item" [routerLink]="formRoutes.favoriteList">{{ 'viewFavorites' | translate }}</a>
              <a class="dropdown-item" (click)="logout()">{{ 'signOut' | translate }}</a>
            </div>
          </div>
        </div>

        <!-- Pre Login -->
        <div class="btn-box signup-in-wrapper" *ngIf="!isLogin">
          <!-- <a [routerLink]="formRoutes.signin" class="btn-style-link call-modal">{{ 'postJob' | translate }}</a> -->
          <a [routerLink]="formRoutes.signin" class="call-modal theme-btn btn-style-one-outline ">{{ 'signIn' | translate }}</a>
          <a [routerLink]="formRoutes.signup" class="theme-btn btn-style-one-outline ">{{ 'signUp' | translate }}</a>



        </div>
      </div>
    </div>
    <hr />
    <nav class="nav main-menu">
      <ul class="navigation" id="navbar">
        <!-- <ng-container *ngIf="userType != '2'">
          <li *ngFor="let c of categories" [class.current]="selectedCategoryId == c?.id"
            (click)="onCategorySelect(c?.id)">
            <span>{{c?.category_name}}</span>
          </li>
        </ng-container> -->

        <!-- <li class="" id="has-mega-menu">
                    <span>Business</span>
                </li>

                <li class="">
                    <span>Design</span>
                </li>
                <li class="">
                    <span>Home Services</span>
                </li>
                <li class="">
                    <span>Electrician Farming</span>
                </li>
                <li class="">
                    <span>Advertising</span>
                </li>
                <li class="">
                    <span>Branding</span>
                </li>
                <li class="">
                    <span>Cars</span>
                </li> -->

        <!-- Only for Mobile View -->
        <li class="mm-add-listing">
          <a href="add-listing.html" class="theme-btn btn-style-one">Job Post</a>
          <!-- <span>
            <span class="contact-info">
              <span class="phone-num"><span>Call us</span><a href="tel:1234567890">123 456 7890</a></span>
              <span class="address">329 Queensberry Street, North Melbourne VIC <br>3051, Australia.</span>
              <a href="mailto:support@superio.com" class="email">support@superio.com</a>
            </span>
            <span class="social-links">
              <a href="#"><span class="fab fa-facebook-f"></span></a>
              <a href="#"><span class="fab fa-twitter"></span></a>
              <a href="#"><span class="fab fa-instagram"></span></a>
              <a href="#"><span class="fab fa-linkedin-in"></span></a>
            </span>
          </span> -->
        </li>
      </ul>
    </nav>
    <!-- Main Menu End-->
  </div>

  <!-- Mobile Header -->
  <div class="mobile-header">
    <div class="logo"><a href="index.html"><img src="assets/images/header-logo.png" alt="" title=""></a></div>

    <!--Nav Box-->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class=" collapse navbar-collapse" id="navbarNavDropdown">


      <div class="nav-outer clearfix">

        <div class="outer-box">
          <!-- Post Login -->
          <div class="btn-box post-login" *ngIf="isLogin">
            <!-- <a routerLink="/messages" class="btn-style-link call-modal">Messages</a> -->
            <a *ngIf="userType == '2'" routerLink="/post-job" class="btn-style-link">{{ 'postJob' | translate }}</a>

            <div class="dropdown user-dropdown">
              <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <!-- <img src="./assets/images/resource/candidate-3.png" />  -->
                <img
                  [src]="userDetails?.image_url ? (userDetails?.image_url | File:'image') : './assets/images/slider_avatar.png'">
                <span class="user-name">Hello {{ userDetails?. first_name}}</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item"
                  [routerLink]="userType == '3' ? formRoutes.editWorkerProfile : formRoutes.editCustomerProfile">{{ 'profile'| translate }}</a>
                <a class="dropdown-item" *ngIf="userType == '3'" [routerLink]="formRoutes.workerJobs">{{ 'viewJobs' | translate }}</a>
                <a class="dropdown-item" *ngIf="userType == '2'" [routerLink]="formRoutes.jobManagement">{{ 'manageJobs' | translate }}</a>
                <a class="dropdown-item" [routerLink]="formRoutes.favoriteList">{{ 'viewFavorites' | translate }}</a>
                <a class="dropdown-item" (click)="logout()">{{ 'signOut' | translate }}</a>
              </div>
            </div>
          </div>

          <!-- Pre Login -->
          <div class="btn-box  signup-in-wrapper" *ngIf="!isLogin">
            <!-- <a routerLink="/signin" class="btn-style-link call-modal">{{ 'postJob' | translate }}</a> -->
            <a routerLink="/signin" class="theme-btn btn-style-one-outline ">{{ 'signIn' | translate }}</a>
            <a routerLink="/signup" class="theme-btn btn-style-one-outline ">{{ 'signUp' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile Nav -->
  <div id="nav-mobile"></div>





</header>
<!--End Main Header -->