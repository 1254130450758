import { Component, Input, OnInit } from '@angular/core';
import { getFileUrl } from 'src/app/core/helpers/helper';
import { Attachment } from 'src/app/core/models/attachment';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent implements OnInit {

  @Input() attachments:Attachment[];
  images = [];

  selectedImage = null;
  currentIndex = 0;
  isFullscreen = false;
  currentStartIndex = 0;
  imagesToShow = 5;

  constructor() { }

  ngOnInit() {
    if(this.attachments){
        this.attachments.forEach(x => {
          let dt = {
            url : getFileUrl(x.fileUrl,'image'),
            title: x.name
          }
          this.images.push(dt);
        })  
    }
  }
    showPrevious() {
      this.currentStartIndex =
        (this.currentStartIndex - 1 + this.images.length) % this.images.length;
    }
    
    showNext() {
      this.currentStartIndex =
        (this.currentStartIndex + 1) % this.images.length;
    }
  

  openFullscreen() {
    this.isFullscreen = true;
  }

  closeFullscreen(): void {
    this.isFullscreen = false; // Close fullscreen view
  }
  
  stopEventPropagation(event: MouseEvent): void {
    event.stopPropagation(); // Prevent click from propagating to the parent container
  }
  
  getVisibleImages() {
    return this.images.slice(
      this.currentStartIndex,
      this.currentStartIndex + this.imagesToShow
    ).concat(
      this.images.slice(0, Math.max(0, (this.currentStartIndex + this.imagesToShow) - this.images.length))
    );
  }

}
