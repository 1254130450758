<!-- Drag and Drop Zone code and upload images start -->


<!-- Drag and Drop Zone -->
<div class="custom-drop-zone" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
  <div class="icon">
    <i class="fa fa-upload fa-3x" aria-hidden="true"></i>
  </div>
  <p class="drop-message">{{ 'dragAndDropFile' | translate }}</p>
  <p>{{ 'or' | translate }}</p>
  <label class="browse-button">
    {{ 'browseForFile' | translate }}
    <input type="file" accept="image/*"  (change)="onFileSelect($event)" multiple style="display: none" />
  </label>
</div>



<!-- File Input -->
<!-- <div class="file-input">
          <label>Multiple</label>
          <input type="file" (change)="onFileSelect($event)" multiple />
        </div>
       -->
<!-- File List -->
<div class="upload-queue" *ngIf="files.length > 0">
  <!-- <h3>Upload queue</h3>
          <p>Queue length: {{ files.length }}</p> -->
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Size</th>
          <th>Progress</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let file of files; let i = index">

          <td>{{ file.name }}</td>
          <td>{{ file.size | number }} bytes</td>
          <td>
            <progress max="100" [value]="file.progress ? file.progress : 0"></progress>
          </td>
          <td>
            <button class="upload" (click)="uploadFile(i)">Upload</button>
            <button class="remove" (click)="removeFile(i)">Remove</button>
          </td>

        </tr>
      </tbody>
    </table>
  </div>

</div>

<!-- Buttons -->
<div class="actions" *ngIf="files.length > 0">
  <button class="upload" style="margin-left: 0;" (click)="uploadAll()">Upload all</button>
  <button class="cancel" (click)="cancelAll()">Cancel all</button>
  <button class="remove" (click)="removeAll()">Remove all</button>
</div>

<!-- Drag and Drop Zone code and upload images end -->