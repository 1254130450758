<div class="startAutoWrapper">
	<button type="button" class="start-button" *ngIf="!isRecording && !blobUrl" (click)="startRecording()">{{ 'startRecording' | translate }}</button>

	<button type="button" class="stop-button" *ngIf="isRecording && !blobUrl" (click)="stopRecording()">{{ 'stopRecording' | translate }}</button>

	<button type="button" class="cancel-button" *ngIf="!isRecording && blobUrl" (click)="clearRecordedData()">{{ 'clearRecording' | translate }}</button>

	<br>
	<button type="button" class="cancel-button" *ngIf="showDownloadBtn && !isRecording && blobUrl" (click)="download()">{{ 'downloadRecording' | translate }}</button>

	<div *ngIf="isRecording && !blobUrl"> {{recordedTime}} </div>
	<div>
		<audio *ngIf="!isRecording && blobUrl" controls>
			<source [src]="blobUrl" type="audio/webm">
		</audio>
	</div>
</div>