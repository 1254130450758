import { environment } from "src/environments/environment";
import { ApiConstants } from "../constants/api.constant";
import { FileItem } from "src/app/shared/file-uploader/file-uploader.component";

export function getFileUrl(path:string,fileType = 'image'){
    const apiUrl =
    fileType === 'image'
      ? environment.fileBaseUrl + ApiConstants.routes.getImage
      : environment.fileBaseUrl + ApiConstants.routes.downloadFile;
    return apiUrl + path.split('\\').join('||');
}

export function convertAttachmentsToFiles(attachments: any[]) {
  const filesToUpload: FileItem[] = [];

  attachments.forEach((attachment) => {
    // Generate the complete file URL
    // const fileUrl = getFileUrl(attachment.fileUrl, attachment.type.startsWith('image') ? 'image' : 'file');
    const fileUrl = getFileUrl(attachment.fileUrl);

    // Fetch the file blob from the URL
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a File object from the blob
        const file = new File([blob], attachment.name, { type: attachment.type });

        // Add to the filesToUpload array
        let dt = {
          file: file,
          name: attachment.name,
          size: attachment.size,
          progress: 100, // Already Uploaded,
          id :attachment.id
        }
        filesToUpload.push(dt);
      })
      .catch((error) => console.error('Error fetching file:', error));
  });

  return filesToUpload;
}