

export class ApiConstants {

  public static routes = {
    login: 'UserManagement/LoginPortal',
    signup: 'UserManagement/RegisterUser',
    verifyOtp: 'UserManagement/VerifyOtp',
    sendOtp: 'UserManagement/SendOtp',
    sendResetCode: 'UserManagement/SendResetCode',
    verifyResetCode: 'UserManagement/VerifyResetCode',
    verifyEmailExists: 'UserManagement/VerifyEmailExists?email=',
    verifyUserName: 'UserManagement/verifyUserName?username=',
    addFavorite: 'UserManagement/AddFavorite',
    removeFavorite: 'UserManagement/RemoveFavorite',
    getUserProfileById: 'UserManagement/getUserProfileById?',
    getUserDetailbyId: 'UserManagement/getUserDetailbyid?',
    getFavorite: 'UserManagement/getFavorite',
    updateUserProfile: 'UserManagement/UpdateUserProfile',
    getFavoriteList: 'UserManagement/getFavoriteList?id=',

    getCategoriesList: 'SkilledServices/GetCategoriesList',
    getSubCategoriesList: 'SkilledServices/GetSubCategoriesList',
    GetCountriesList: 'SkilledServices/GetCountriesList',
    getCitiesList: 'SkilledServices/GetCitiesList',
    getAreaList: 'SkilledServices/GetAreaList',
    getCitiesListv2: 'SkilledServices/V2/GetCitiesList',
    getAreaListv2: 'SkilledServices/V2/GetAreaList',
    // postService: 'SkilledServices/PostService',
    // getServicesbyProviderId: 'SkilledServices/getServicesbyProviderID',
    // getProviderById: 'SkilledServices/findProviderProfileByID',
    getProvidersList : 'SkilledServices/GetProvidersList',
    getProvidersById: 'SkilledServices/GetProvidersById?',
    addBookingServiceGeneralInfo: 'SkilledServices/AddBookingServiceGeneralInfo',
    addBookingServiceScheduleInfo: 'SkilledServices/AddBookingServiceScheduleInfo',
    addBookingServicePaymentMethod: 'SkilledServices/AddBookingServicePaymentMethod',
    getFeaturedProvidersList: 'SkilledServices/GetFeaturedProvidersList',
    getFeaturedCategoriesList: 'SkilledServices/GetFeaturedCategoriesList',

    getJobDetailbyid: 'Jobs/GetJobDetailById?',
    getJobsList: 'Jobs/GetJobsList',
    getJobTypesList: 'Jobs/jobTypesList',
    applyJob: 'Jobs/ApplyJob',
    postJob: 'Jobs/PostJob',
    DeleteJob: 'Jobs/DeleteJob',
    getJobProposalById: 'Jobs/GetJobProposalById?id=',
    approveJobProposal:'Jobs/ApproveJobProposal',
    addFeedback: 'Jobs/AddFeedback',
    endJob: 'Jobs/EndJob',
    getFeaturedJobsList: 'Jobs/GetFeaturedJobsList',
    Quotations: 'Quotations/', 

    Upload: 'Generic/Upload',
    UploadFiles: 'Generic/Upload-Files',
    getImage: 'GetImage/',
    downloadFile: 'DownloadFile/'
  }

}
