<!-- Slider -->
<div style="position: relative; overflow: hidden;">
  <!-- Previous Button -->
  <button class="nav-btn prev" (click)="showPrevious()">&#10094;</button>
  <div class="slider">


    <!-- Visible Images -->
    <div class="slider-content">
      <div class="image-container" *ngFor="let image of getVisibleImages()">
        <img [src]="image.url" [alt]="image.title" (click)="openFullscreen(image)" />
        <div class="image-title">{{ image.title }}</div>
      </div>
    </div>


  </div>
  <!-- Next Button -->
  <button class="nav-btn next" (click)="showNext()">&#10095;</button>
</div>
<!-- Fullscreen Modal -->
<!-- <div class="fullscreen" *ngIf="isFullscreen">
            <div class="fullscreen-overlay" (click)="closeFullscreen()"></div>
            <div class="fullscreen-content" (click)="stopEventPropagation($event)">
              <button class="nav-btn prev" (click)="showPrevious()">&#10094;</button>
              <img [src]="images[currentStartIndex].url" [alt]="images[currentStartIndex].title" />
              <div class="fullscreen-title">{{ images[currentStartIndex].title }}</div>
              <button class="nav-btn next" (click)="showNext()">&#10095;</button>
              <button class="close-btn" (click)="closeFullscreen($event)">&#10005;</button>
            </div>
          </div> -->
<div class="fullscreen" *ngIf="isFullscreen" (click)="closeFullscreen()">
  <!-- Content area where propagation should be stopped -->
  <div class="fullscreen-content" (click)="stopEventPropagation($event)">
    <button class="nav-btn prev" (click)="showPrevious()">&#10094;</button>
    <img [src]="images[currentStartIndex].url" [alt]="images[currentStartIndex].title" />
    <div class="fullscreen-title">{{ images[currentStartIndex].title }}</div>
    <!-- <button class="close-btn" (click)="closeFullscreen()">&#10005;</button> -->
    <button class="nav-btn next" (click)="showNext()">&#10095;</button>
  </div>
</div>