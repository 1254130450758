import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation.service';

@Pipe({
    name: 'translate',
    pure: false,
})
export class TranslationPipe implements PipeTransform {
    constructor(private translationService: TranslationService) { }

    transform(key: string, args: any[] = []): string {
        if (this.translationService.translations) {
            let localizedText = this.translationService.translations[key] || key;
            if (localizedText.includes('{{}}')) {
                localizedText = this.populateDynamicValues(localizedText, args);
            }
            return localizedText;
        } else {
            return key;
        }
    }

    populateDynamicValues(localizedText: string, args: any[]) {
        args.forEach((arg, i) => {
            let currentIndex = localizedText.indexOf('{{}}', i);
            localizedText = localizedText.substr(0, currentIndex) + arg + localizedText.substr(currentIndex + 4);
        });
        return localizedText;
    }

};
