import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
export interface FileItem {
  id?:string;
  file: File;
  name: string;
  size: number;
  progress?: number;
}
@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  @Input() files: FileItem[]; //Use to save all files 
  @Output() uploadedFiles = new EventEmitter<FileItem[]>();  //use to save only uploaded / uploaded on server
  constructor() { }

  ngOnInit(): void {
  }
 // Handle drag-over events
 onDragOver(event: DragEvent): void {
  event.preventDefault();
  event.stopPropagation();
  (event.currentTarget as HTMLElement).classList.add('drag-over');
}

// Handle drag-leave events
onDragLeave(event: DragEvent): void {
  event.preventDefault();
  (event.currentTarget as HTMLElement).classList.remove('drag-over');
}

// Handle file drop
onDrop(event: DragEvent): void {
  event.preventDefault();
  event.stopPropagation();
  (event.currentTarget as HTMLElement).classList.remove('drag-over');

  const droppedFiles = event.dataTransfer?.files;
  if (droppedFiles) {
    this.addFiles(droppedFiles);
  }
}

// Handle file selection
onFileSelect(event: Event): void {
  const input = event.target as HTMLInputElement;
  if (input.files) {
    this.addFiles(input.files);
    input.value = ''; // Clear input
  }
}

// Add files to the list
addFiles(fileList: FileList): void {
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i];
    this.files.push({
      file,
      name: file.name,
      size: file.size,
      progress: 0
    });
  }
}

// file upload progress
uploadFile(index: number): void {
  const file = this.files[index];
  if (!file) return;

  const interval = setInterval(() => {
    if (file.progress! < 100) {
      file.progress! += 10;
    } else {
      clearInterval(interval);
      this.emitUploadedFiles();
      
    }
  }, 300);
}
emitUploadedFiles(): void {
  const uploadedFiles = this.files.filter(file => file.progress === 100); //only upload new files on server
  this.uploadedFiles.emit(uploadedFiles); // Emit files that have been fully uploaded
}
// Upload all files
uploadAll(): void {
  this.files.forEach((_, index) => this.uploadFile(index));
}

// Remove a single file
removeFile(index: number): void {
  this.files.splice(index, 1);
  this.emitUploadedFiles();
}

// Remove all files
removeAll(): void {
  this.files = [];
  this.emitUploadedFiles();
}

// Cancel all uploads
cancelAll(): void {
  this.files.forEach((file) => (file.progress = 0));
  this.emitUploadedFiles();
}
}
